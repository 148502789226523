import React from 'react';

import PropTypes from 'prop-types';

const App = ( { images } ) => {

    return(
        <div id="app">
            <div id="screens" className="pos-rel z-0">
                <div>
                    <img src={ images.sslogo } />
                </div>
            </div>
        </div>
    )
}

App.propTypes = {
    images: PropTypes.object
}

export default App
